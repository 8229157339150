<template>
    <div>
        <v-card>
            <v-card-title ref="title">
                <v-btn
                    class="mr-3"
                    depressed
                    color="primary"
                    icon
                    rounded
                    small
                    @click="closeTimeLine()"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <h3>Purchase {{ request.number }}</h3>
            </v-card-title>
            <v-divider class="ml-4 mr-5"></v-divider>
            <v-card-text
                class="mt-4 overflow-y-auto"
                :style="`max-height: ${height}px`"
            >
                <v-timeline dense class="pr-6" align-top>
                    <div v-for="(item, index) in itemsTimeLine" :key="index">
                        <v-timeline-item
                            small
                            :color="statusColors[item.status].color"
                        >
                            <!-- Timeline content -->
                            <v-row justify="space-between">
                                <v-col cols="6">
                                    <span
                                        class="text-capitalize font-weight-bold"
                                    >
                                        {{ item.status }}
                                    </span>
                                    <p>
                                        {{ item.userName }}
                                    </p>
                                </v-col>
                                <v-col class="text-right" cols="6">
                                    {{ item.date }}
                                </v-col>
                            </v-row>
                        </v-timeline-item>
                    </div>
                </v-timeline>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'
export default {
    name: 'TimeLinePurchase',
    props: {
        request: {
            type: Object,
            default: () => ({}),
            required: true,
        },
        users: {
            type: Array,
            default: () => [],
            required: true,
        },
    },
    data: () => ({
        statusColors: Object.freeze({
            inProgress: {
                text: 'inProgress',
                color: 'primary',
            },
            pricing: {
                text: 'pricing',
                color: '#3293e6',
            },
            preApproval: {
                text: 'preApproval',
                color: '#FF9800',
            },
            approval: {
                text: 'approval',
                color: '#27BC4F',
            },
            purchasing: {
                text: 'purchasing',
                color: 'primary',
            },
            inTransit: {
                text: 'inTransit',
                color: '#ff7800',
            },
            received: {
                text: 'received',
                color: '#00a135',
            },
            closed: {
                text: 'closed',
                color: '#585454',
            },
            rejected: {
                text: 'rejected',
                color: 'red',
            },
        }),
        itemsTimeLine: [],
        height: 0,
    }),
    async mounted() {
        await this.TimeLine(this.request)
        this.onResize()
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async TimeLine(request) {
            try {
                let pricingBy = request.buyer ? this.getUser(request.buyer) : ''
                let pricingDate = null
                let pricingDateSeconds = null

                if (request.inProgressDate || request.assignDate) {
                    if (
                        request.inProgressDate &&
                        (request.inProgressDate.seconds != null ||
                            request.inProgressDate._seconds != null)
                    ) {
                        pricingDate = this.formatDate(
                            request.inProgressDate.seconds ||
                                request.inProgressDate._seconds
                        )
                        pricingDateSeconds =
                            request.inProgressDate.seconds ||
                            request.inProgressDate._seconds
                    } else if (
                        request.assignDate.seconds != null ||
                        request.assignDate._seconds != null
                    ) {
                        pricingDate = this.formatDate(
                            request.assignDate.seconds ||
                                request.assignDate._seconds
                        )
                        pricingDateSeconds =
                            request.assignDate.seconds ||
                            request.assignDate._seconds
                    }
                }

                let preApprovedBy = request.pricingBy
                    ? this.getUser(request.pricingBy)
                    : null
                let preApprovedDate = request.pricingDate
                    ? this.formatDate(
                          request.pricingDate.seconds ||
                              request.pricingDate._seconds
                      )
                    : null
                let preApprovedDateSeconds = request.pricingDate
                    ? request.pricingDate._seconds ||
                      request.pricingDate.seconds
                    : null
                let approvedBy = request.preApprovedBy
                    ? this.getUser(request.preApprovedBy)
                    : null
                let approvedDate = request.preApprovedDate
                    ? this.formatDate(
                          request.preApprovedDate.seconds ||
                              request.preApprovedDate._seconds
                      )
                    : null
                let approvedDateSeconds = request.preApprovedDate
                    ? request.preApprovedDate.seconds ||
                      request.preApprovedDate._seconds
                    : null
                let purchasedBy = request.approvedBy
                    ? this.getUser(request.approvedBy)
                    : null
                let purchasedDate = request.approvedDate
                    ? this.formatDate(
                          request.approvedDate.seconds ||
                              request.approvedDate._seconds
                      )
                    : null
                let purchaseDateSeconds = request.approvedDate
                    ? request.approvedDate.seconds ||
                      request.approvedDate._seconds
                    : null
                let inTransitBy = request.purchasedBy
                    ? this.getUser(request.purchasedBy)
                    : null
                let inTransitDate = request.purchasedDate
                    ? this.formatDate(
                          request.purchasedDate.seconds ||
                              request.purchasedDate._seconds
                      )
                    : null
                let inTransitDateSeconds = request.purchasedDate
                    ? request.purchasedDate.seconds ||
                      request.purchasedDate._seconds
                    : null
                let receivedBy = request.inTransitBy
                    ? this.getUser(request.inTransitBy)
                    : ''
                let receivedDate = request.inTransitDate
                    ? this.formatDate(
                          request.inTransitDate.seconds ||
                              request.inTransitDate._seconds
                      )
                    : ''
                let receivedDateSeconds = request.inTransitDate
                    ? request.inTransitDate._seconds ||
                      request.inTransitDate.seconds
                    : ''
                let closedBy = request.receivedBy
                    ? this.getUser(request.receivedBy)
                    : ''
                let closedDate = request.closedDate
                    ? this.formatDate(
                          request.closedDate.seconds ||
                              request.closedDate._seconds
                      )
                    : ''
                let closedDateSeconds = request.closedDate
                    ? request.closedDate.seconds || request.closedDate._seconds
                    : ''
                let rejectedBy = request.rejectedBy
                    ? this.getUser(request.rejectedBy)
                    : ''
                let rejectedDate = request.rejectedDate
                    ? this.formatDate(
                          request.rejectedDate.seconds ||
                              request.rejectedDate._seconds
                      )
                    : ''
                let rejectedDateSeconds = request.rejectedDate
                    ? request.rejectedDate.seconds ||
                      request.rejectedDate._seconds
                    : ''

                this.itemsTimeLine.push({
                    status: 'inProgress',
                    color: this.statusColors.inProgress.color,
                    userName: request.createdBy.name,
                    date: this.formatDate(
                        request.createdOn.seconds || request.createdOn._seconds
                    ),
                    seconds:
                        request.createdOn.seconds || request.createdOn._seconds,
                })

                if (request.rejectedBy) {
                    this.itemsTimeLine.push({
                        status: 'rejected',
                        color: this.statusColors.rejected.color,
                        userName: rejectedBy,
                        date: rejectedDate,
                        seconds: rejectedDateSeconds,
                    })
                }

                if (request.buyer && request.buyer != null) {
                    this.itemsTimeLine.push({
                        status: 'pricing',
                        color: this.statusColors.pricing.color,
                        userName: pricingBy,
                        date: pricingDate,
                        seconds: pricingDateSeconds,
                    })
                }

                if (request.pricingBy) {
                    this.itemsTimeLine.push({
                        status: 'preApproval',
                        color: this.statusColors.preApproval.color,
                        userName: preApprovedBy,
                        date: preApprovedDate,
                        seconds: preApprovedDateSeconds,
                    })
                }

                if (request.preApprovedBy) {
                    this.itemsTimeLine.push({
                        status: 'approval',
                        color: this.statusColors.approval.color,
                        userName: approvedBy,
                        date: approvedDate,
                        seconds: approvedDateSeconds,
                    })
                }

                if (request.approvedBy) {
                    this.itemsTimeLine.push({
                        status: 'purchasing',
                        color: this.statusColors.purchasing.color,
                        userName: purchasedBy,
                        date: purchasedDate,
                        seconds: purchaseDateSeconds,
                    })
                }

                if (request.purchasedBy) {
                    this.itemsTimeLine.push({
                        status: 'inTransit',
                        color: this.statusColors.inTransit.color,
                        userName: inTransitBy,
                        date: inTransitDate,
                        seconds: inTransitDateSeconds,
                    })
                }

                if (request.inTransitBy) {
                    this.itemsTimeLine.push({
                        status: 'received',
                        color: this.statusColors.received.color,
                        userName: receivedBy,
                        date: receivedDate,
                        seconds: receivedDateSeconds,
                    })
                }
                if (request.receivedBy) {
                    this.itemsTimeLine.push({
                        status: 'closed',
                        color: this.statusColors.closed.color,
                        userName: closedBy,
                        date: closedDate,
                        seconds: closedDateSeconds,
                    })
                }
                this.itemsTimeLine.sort(function(a, b) {
                    return a.seconds - b.seconds
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        closeTimeLine() {
            this.itemsTimeLine = []
            this.$emit('closeDialog')
        },
        formatDate(seconds) {
            const date = moment.unix(seconds).format('YYYY-MM-DD HH:mm:ss')
            return date
        },
        getUser(id) {
            const user = this.users.find(u => u.id == id)
            if (user) {
                return user.name
            }
            return null
        },
        onResize() {
            const {
                title: { clientHeight: titleHeight },
            } = this.$refs
            this.height = window.innerHeight - titleHeight - 90
        },
    },
}
</script>
